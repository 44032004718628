
// Booststrap functions and variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Template variables
@import 'variables';
@import 'variables';

// SCSS compass funtions
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';

// Bootstrap main SCSS
@import '~bootstrap/scss/bootstrap.scss';

// Template mixins
@import 'mixins/animation';
@import 'mixins/badges';
@import 'mixins/blockquote';
@import 'mixins/breadcrumbs';
@import 'mixins/buttons';
@import 'mixins/cards';
@import 'mixins/misc';
@import 'mixins/no_ui_slider';
@import 'mixins/pagination';
@import 'mixins/popovers';
@import 'mixins/tooltips';
@import 'mixins/width';

// Core stules
@import 'background';
@import 'reset';
@import 'functions';
@import 'fonts';
// @import url('https://fonts.googleapis.com/css?family=Overpass:300,400,600,700,800');
@import 'footer';
@import 'misc';
@import 'utilities';
@import 'demo';
@import 'typography';
@import 'spinner';

@import 'vertical-wrapper';
@import 'navbar';
@import 'sidebar';
@import 'layouts';

// components
@import "components/accordions";
@import "components/badges";
@import "components/bootstrap-alert";
@import "components/breadcrumbs";
@import "components/buttons";
@import "components/cards";
@import "components/checkbox-radio";
@import "components/dashboard";
@import "components/dropdown";
@import "components/forms";
@import "components/icons";
@import "components/input-group";
@import "components/list-group";
@import "components/modal";
@import "components/nav";
@import "components/pagination";
@import "components/popover";
@import "components/profile";
@import "components/tables";
@import "components/timeline";
@import "components/chat";
@import "components/faq";
@import "components/auth";

// Email
@import 'components/email/inbox';
@import 'components/email/email-read';
@import 'components/email/email-compose';


// Plugin overrides
@import "components/plugin-overrides/ace";
@import "components/plugin-overrides/apex-charts";
@import "components/plugin-overrides/bootstrap-datepicker";
@import "components/plugin-overrides/data-tables";
@import "components/plugin-overrides/dropify";
@import "components/plugin-overrides/dropzone";
@import "components/plugin-overrides/full-calendar";
@import "components/plugin-overrides/jquery-flot";
@import "components/plugin-overrides/peity";
@import "components/plugin-overrides/perfect-scrollbar";
@import "components/plugin-overrides/sweet-alert";
@import "components/plugin-overrides/select2";
@import "components/plugin-overrides/simplemde";
@import "components/plugin-overrides/tags-input";
@import "components/plugin-overrides/tempusdominus-bootstrap-4";
@import "components/plugin-overrides/tinymce";
@import "components/plugin-overrides/typeahead";
@import "components/plugin-overrides/wizard";

.select2-selection{
    min-height: 32px;
}

 .modal-serial-selected{
     border-bottom: 5px solid #f89b24;
 }

.kbw-signature { width: 100%; height: 200px;}
#sig canvas{
    width: 100% !important;
    height: auto;
}

.notifications-body {
    padding: 5px;
    position: relative;
    .notifications-item {
        @include display-flex;
        @include align-items(center);
        padding: 9px 16px;
        transition: background .2s ease-in-out;
        &:hover {
            background: rgb(248, 248, 248);
            .arrow {
                color: theme-color(primary);
            }
        }
        .icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            background: rgb(247, 247, 247);
            svg {
                width: 15px;
                height: 15px;
            }
        }
        .figure {
            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }

        &:hover {
            background: rgba(theme-color(primary), .07);
            .arrow {
                color: theme-color(primary);
            }
        }
        .icon {
            background: rgba(theme-color(primary), .1);
            svg {
                color: theme-color(primary);
            }
        }

        .content {
            margin-left: 15px;
            width: 100%;
            white-space: normal;
            p {
                margin-bottom: 0;
            }
            .sub-text {
                font-size: 12px;
            }
        }
        .arrow {
            width: 15px;
            height: 15px;
            margin-left: auto;
            color: #ddd;
            transition: all .3s ease-out;
        }
    }
}

.modal-sm { max-width: 100%; }

@include media-breakpoint-up(md){
    .modal-xl{
        max-width: 95% !important;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
        max-width: $modal-lg !important;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl { max-width: $modal-xl !important; }
}
