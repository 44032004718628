.dataTables_wrapper {
  &.dt-bootstrap4 {
    .dataTables_length {
      @media(max-width: 767px) {
        text-align: left;
      }
      select {
        @extend .w-100;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .dataTables_filter {
      @media(max-width: 767px) {
        text-align: left;
        margin-left: -19px;
      }
    }
  }
  div {
    &.dataTables_paginate {
      ul {
        &.pagination {
          @extend .justify-content-center;
          @extend .justify-content-md-end;
          @extend .mt-3;
          @extend .mt-md-0;
        }
      }
    }
  }
}

.rtl {
  .dataTables_wrapper {
    .dataTables_filter {
      text-align: left;
      input {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
    > div {
      &.row {
        div[class^="col-"]:first-child {
          padding-right: 0;
        }
        div[class^="col-"]:last-child {
          padding-left: 0;
        }
      }
    }
  }
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: theme-color(primary);
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: theme-color(primary);
    border-color: theme-color(primary);
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}
